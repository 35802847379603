import { computed } from 'vue';
import { useStore } from 'vuex';

export default () => {
    const store = useStore();

    const userPermissions = computed(() => {
        return store.getters['auth/getUserPermissions'];
    });
    const userRoles = computed(() => {
        return store.getters['auth/getUserRoles'];
    });

    return {
        userPermissions,
        userRoles
    };
};
